/* common css ******************************************************************************/



/* Inter  */
@font-face{
  font-family: "Inter-Regular";
  src: url("../fonts/Inter-Regular.ttf");
}
@font-face{
  font-family: "Inter-Medium";
  src: url("../fonts/Inter-Medium.ttf");
}
@font-face{
  font-family: "Inter-SemiBold";
  src: url("../fonts/Inter-SemiBold.ttf");
}
/* Fredoka */
@font-face{
  font-family: "Fredoka-Regular";
  src: url("../fonts/Fredoka-Regular.ttf");
}
@font-face{
  font-family: "Fredoka-Medium";
  src: url("../fonts/Fredoka-Medium.ttf");
}
:root {
  --white: #fff;
  --black: #000;
  --light-black:#00000045;
  --orange-E67A30: #49DADA;
  --uncheck-color:hsla(180, 66%, 57%, 0.1);
  --grey-9A9EAC:  #9A9EAC;
  --light-orange-D67367:#49DADA;
  --border-color:#333844;
  --dark-bg-color: #1F2128;
  --card-light-bg:#383B45;
  --skyblue:#30A4E6;
  --green:#00C282;
  --red:#D23C3C;
  --yellow:#D2CA00;
  --text-color:#686B76;
  --light-blue-bg-color:#21222D;
  --card-bg-color:#171821;
  --active-color:#494F61;
  --light-yellow:#E6DF30;
  --gray-text:#7A7A7A;


  --chat-35373D:#35373D;
  --chat-active:#636467;
  --profile-header-color:#2E3039;
  --light-white:#ffffff10;
  --footer-bg:#3B3E46;
  --light-gray:#D9D9D9;
  
}


/* Common Use css start  */

.orange-color{
  background: var(--orange-E67A30);
  color: var(--black) !important;
}
.white {
  color: var(--white);
}
.black {
  color: var(--black);
}
.green {
  color: var(--green);
}
.dark-green {
  color: var(--dark-green);
}
.bg-green {
  background-color: var(--green);
  color: var(--black) !important;
}
.skyblue {
  color: var(--skyblue);
}
.yellow {
  color: var(--yellow);
}
.bg-yellow{
  background-color: var(--light-yellow);
  color: var(--black) !important;
}
.bg-black{
  background-color: var(--black);
  color: var(--white) !important;
}
.red {
  color: var(--red);
}
.bg-red {
  background-color: var(--red);
  color: var(--black) !important;

}
.orange{
  color: var(--orange-E67A30);
}

.margin-top {
  margin-top: 80px;
}


/* Common Use css End  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
*::before,
*::after {
  box-sizing: border-box;
}
.common::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #000;
}

body {
  background: var(--black);
  font-family: "Inter-Medium";

}
body.show {
  position: fixed;
  top: 0;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
  line-height: 1.2;
  color: var(--white);
  font-weight: 700;
}
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}
h1 {
  font-size: 52px;
}
h2 {
  font-size: 44px;
}
h3 {
  font-size: 36px;
}
h4 {
  font-size: 28px;
}
h5 {
  font-size: 24px;
}
h6 {
  font-size: 20px;
}
p {
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 25px;
  color: var(--white);
}
p:last-child {
  margin-bottom: 0;
}
.small {
  font-size: 16px;
}
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  text-transform: capitalize;
  display: inline-block;
  line-height: 1.2;
  color: inherit;
  font-family: inherit;
}
a:hover{
  color: inherit !important;
}
span {
  display: inline-block;
}
.section-heading {
text-align: center;
margin-bottom: 50px;
}
.btn-common {
font-size: 20px;
padding: 12px 30px;
color: #cbccd0;
border-radius: 13px;
border: 1px solid transparent;
display: inline-flex;
align-items: center;
justify-content: center;
  column-gap: 10px;
}
.btn-common img {
  max-width: 30px;
  margin-right: 10px;
}

button:focus{
  box-shadow:  none !important;
}
section {
overflow: hidden;
}
.bg {
  padding: 100px 0;
}
.mobile {
  display: none !important;
}
.desktop {
  display: block !important;
}
img,
video {
  max-width: 100%;
}
.button-box {
padding-top: 20px;
}
.button-box ul {
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 15px;
}
.owl-theme .owl-dots .owl-dot span {
  background: var(--black);
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--white);
  outline: 2px solid var(--white);
  outline-offset: 3px;
}
.owl-nav {
  margin-top: 0;
}
.owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.owl-nav button.owl-prev {
  left: 0;
}
.owl-nav button.owl-next {
  right: 0;
}
.form-control-common {
background: var(--white);
border: 1px solid var(--black);
font-size: 20px;
padding: 15px 50px 15px 20px;
width: 100%;
appearance: textfield;
color: var(--black);
border-radius: 0;
box-shadow: none;
resize: none;
  outline: none;
}
.input-group {
padding-bottom: 30px;
}

input:focus{
  box-shadow: none !important;
}

.form-select:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: 0 !important;
}

#scrollbar::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
border-radius: 10px;
background-color: var(--dark-bg-color);
margin-left: 5px;
}

#scrollbar::-webkit-scrollbar{
width: 6px;
background-color: var(--dark-bg-color);
  display: none;
}

#scrollbar::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
background-color: var(--active-color);
}
#scrollbar{
scrollbar-width: none;  /* Firefox */
}

::placeholder {
  color: #6c757d !important;
}



/* Log in page css ******************************************************************************/
.main-wrapper{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
  position: relative;
}

.main-wrapper::before {
  content: "";
  position: absolute;
  width: 190px;
  height: 190px;
  background: var(--orange-E67A30);
  bottom: 0;
  left: 0;
  border-radius: 50%;
  filter: blur(211px);
}
.main-wrapper::after {
  content: "";
  position: absolute;
  width: 190px;
  height: 190px;
  background: var(--orange-E67A30);
  top: 0;
  right: 0;
  border-radius: 50%;
  filter: blur(211px);
}

.main-wrapper .login-box .card {
  background-color: transparent !important;
  border: 1px solid rgba(0,0,0,.125);
  border-radius:0 ;
}
.main-wrapper .login-box .card  .logo-box{
  margin-bottom: 20px;
}
.main-wrapper .login-box  .card  .logo-box img{
  width: 100%;
  max-width: 90%;
}
.main-wrapper .login-box .card .card-title h3{
  color: var(--white);
  font-weight: 500;
  font-family: "Inter-SemiBold";
  font-size: 34px;
}

.main-wrapper .login-box  .card .card-title p{
  font-size: 18px;
  font-family: "Inter-Regular";
  color: var(--grey-9A9EAC);
  margin-bottom: 0;
}
.main-wrapper .login-box  .card .card-body{
  margin-top: 15px;
}
.main-wrapper .login-box .form-group {
  margin-bottom: 20px;
}
.main-wrapper  .login-box  .card  .form-label {
  color: var(--grey-9A9EAC);
  font-size: 15px;
}
.main-wrapper .login-box  .card  .form-label span{
  color: var(--light-orange-D67367);
}
.main-wrapper .login-box  .card .form-control {
  background-color: var(--dark-bg-color);
  border-color: #333844;
  color: var(--grey-9A9EAC);
  border-radius: 8px;
  padding: 10px;
}
.main-wrapper .login-box   .card .card-body .btn {
  background-color: var(--orange-E67A30);
  color: var(--black);
  font-weight: 500;
  border-radius: 8px;
  padding: 10px 12px;
  font-family: "Inter-SemiBold";
  width: 100%;
  margin-top: 10px;
}

.main-wrapper .login-box  
.form-group  .toggle-password {
  float: right;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -30px;
  color: var(--grey-9A9EAC);
}


/* verification page  */


.main-wrapper .verification-box   .change-nb{
  margin-top: 15px;
}
.main-wrapper .verification-box  .change-nb a {
  color: var(--white);
  border-bottom: 1px solid var(--white);
}
.main-wrapper .verification-box  .otp-field {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 15px 0;
}

.main-wrapper .verification-box  .otp-field input {
  width: 44px;
  height: 44px;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  margin: 2px;
  border: 1px solid var(--border-color);
  background: var(--dark-bg-color);
  font-weight: bold;
  color: #fff;
  outline: none;
  transition: all 0.1s;
}

/* .otp-field input:focus {
  border: 2px solid #a527ff;
  box-shadow: 0 0 2px 2px #a527ff6a;
} */

.main-wrapper .verification-box  .card .card-title p.number{
  color: var(--white);
  padding-top: 10px;
}
.main-wrapper .verification-box .card  p.otp{
  color: var(--white);
  text-align: center;
  margin-bottom: 0;
  font-size: 15px;
}

.container-box {
  display: flex;
}

.container-fluid {
  padding: 0;
}

/* --- Right sidebar css */
.right-sidebar {
	width: 100%;
	background-color: var(--black);
	min-height: 100vh;
	overflow: auto ;
}
.right-sidebar .contant-box {
  /* padding: 30px 30px ; */
  background-color: var(--black);
  min-height: calc(100vh - 90px);
}

.chat-box.chat-box-left {
  background: var(--dark-bg-color);
  padding: 18px 25px;
  height: 100%;

}
.left-user-box .contact-profile{
  padding: 0 25px;
  background: var(--profile-header-color);
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;
  
}
.left-user-box .contact-profile.big{
  padding: 38px 25px;
}
.left-user-box .contact-profile > img{
  width: 54px;
  height: 54px;
  max-width: 100%;
  border-radius: 50%;
}

#chatSection .left-user-box .contact-profile .call-setting-tab {
  margin-left: auto;
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
}

#chatSection .left-user-box .contact-profile .call-setting-tab > .btn {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  /* background: var(--light-blue-bg-color); */
  position: relative;
  margin-left: 10px;
}

#chatSection .left-user-box .contact-profile .call-setting-tab > .btn > img ,
#chatSection .left-user-box .contact-profile .call-setting-tab > .btn > i {
  color: var(--white);
  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.left-user-box .call-setting-tab ul.dropdown-menu.show {
  transform: translate(290px, 75px) !important;
}
.call-setting-tab ul li{
  margin-bottom: 8px;
}
#chatSection nav {
  /* background: var(--light-blue-bg-color); */
  padding: 15px 0;
  border-radius: 15px !important;
  margin-bottom: 0;
}
#chatSection .nav-tabs {
  column-gap: 20px;
}
#chatSection .nav-tabs .nav-link{
  color: var(--gray-text);
  background:var(--light-white) ;
  border-radius: 13px;
  width: 30%;
}
#chatSection .nav-tabs .nav-link:hover,
#chatSection .nav-tabs .nav-link:focus{
  border-color: transparent;
  isolation: unset;
}
#chatSection .nav-tabs .nav-link.active {
  color: var(--black) !important;
  background-color: var(--orange-E67A30);
  border: none !important;
  border-radius: 13px;
  line-height: 1;
}

.serach-button-tab .form-group {
  position: relative;
}
#chatSection .form-group .form-control {
  background: var(--chat-35373D);
  padding: 15px 15px 15px 60px;
  margin-bottom: 0;
  border: none;
  color: var(--white);
  border-radius: 16px;
  font-family: "Inter-Medium";
  margin: 0 0 15px 0;
}
.serach-button-tab .form-group i {
  position: absolute;
  top: 50%;
  left: 5%;
  color: var(--white);
  font-size: 18px;
  transform: translateY(-50%);
}
#chatSection  .user-chat-nav {
  /* background: var(--black) !important; */
  padding: 0;
  height: calc(100vh - 268px);
  overflow-y: auto;
}

#chatSection  .newgroup-box{
  height: calc(100vh - 368px);
}
p.orange{
  margin-bottom: 10px;
  font-size: 16px;

}
#chatSection .user-chat-nav .nav-tabs {
  display: block;
}
#chatSection .user-chat-nav  .nav-link {
  border: none !important;
  padding: 10px;
  color: var(--white);
  border-radius: 15px;
  font-family: "Inter-Medium";
  background: var(--chat-35373D);
  width: 100%;
  margin-bottom: 15px;
}
#chatSection .user-chat-nav .nav-link.active {
  color: var(--white)!important;
  background-color: var(--chat-active);
  border: none !important;
  border-radius: 13px;
}
#chatSection .user-chat-nav .user-chat-box a .img-box{
  width: 54px;
  height: 54px;
  max-width: 100%;
}
#chatSection .user-chat-nav .user-chat-box a .img-box img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
#chatSection .user-chat-nav .user-chat-box a .user-info {
  margin-left: 15px;
}
#chatSection .user-chat-nav .user-chat-box a .user-info h5 {
  margin-bottom: 0;
  color: var(--white);
  font-size: 20px;
  font-family: "Inter-SemiBold";
}
#chatSection .user-chat-nav .user-chat-box a .user-info p {
  color: var(--white);
  font-size: 17px;
  font-family: "Fredoka-Regular";
}
#chatSection .user-chat-nav .user-chat-box .time {
  color: var(--white);
  margin-bottom: 0;
  font-size: 14px;
}
#chatSection .user-chat-nav .chat-time span {
  height: 25px;
  width: 25px;
  line-height: 25px;
  /* border: 1px solid #000; */
  border-radius: 50%;
  background-color: var(--orange-E67A30);
  color: var(--black);
  font-size: 11px;
}

/* Right Chat box  */


#chatSection .chat-box-right {
  /* padding: 15px 13px; */
  /* background: url('../images/chat-bg.png'); */
  background-position: center;
  background-size: 100% 100%;
  /* border-radius: 15px; */
  /* height: calc(100vh - 150px); */
}

#chatSection .chat-box-right .contact-profile {
  background: var(--dark-bg-color);
  padding: 18px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#chatSection .chat-box-right .contact-profile img {
  border-radius: 12px;
  width: 54px;
  height: 54px;
  max-width: 100%;
}
.chat-box-right .contact-profile .user-info {
  margin-left: 15px;
}
#chatSection .chat-box-right .contact-profile .call-setting-tab {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
}
.call-setting-tab h5,
.chat-box-right .contact-profile h5 {
  margin-bottom: 0;
  color: var(--white);
  font-size: 20px;
  font-family: "Inter-SemiBold";
}
.call-setting-tab h5 {
  font-size: 22px;
}
#chatSection .chat-box-right .contact-profile .call-setting-tab {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
}
#chatSection .chat-box-right .contact-profile .call-setting-tab > .btn {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  /* background: var(--light-blue-bg-color); */
  position: relative;
  margin-left: 10px;
}
#chatSection .chat-box-right .contact-profile .call-setting-tab > .btn > i {
  color: var(--white);
  font-size: 25px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.new-group-box{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 30px;
}

.chat-box-left  .new-group-box .new-grp {
  width: 100%;
  display: flex;
  align-items: end;
}
.chat-box-left .new-group-box span.title {
  color: white;
  border-bottom: 1px solid var(--chat-active);
  display: block;
  padding-bottom: 20px;
}

.image {
  position: relative;
  background: #1F2128;
  overflow: hidden;
}
.image img {
  width: 100%;
  max-width: 70px;
}
.image img.profile-update {
  max-width: 100%;
  width: 100%;
  border-radius: 50%;
}
.image input.form-control {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  /* z-index: 99; */
}

.new-group-box .form-control {
  background: var(--dark-bg-color);
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid var(--border-color);
  color: var(--text-color);
  border-radius:0;
  font-size: 18px;
  width: 100%;
  padding: 12px 6px;
  /* color: var(--white) !important; */
}
.new-group-box .form-control.white::placeholder{
  color: var(--white);
}
.new-group-box .form-control:focus{
  background: var(--dark-bg-color);
  box-shadow: none;
  border-color: var(--border-color);
}

/* New group page css  **************************************************/
.new-group {
  height: calc(100vh - 121px) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selected-user-tab .nav-link{
  background: var(--chat-35373D);
  padding:  6px;
  border-radius: 10px;
}
.selected-user-tab .user-chat-box  .user-info h5{
font-size: 11px;
font-weight: 400;
margin-left: 5px;
}
.selected-user-tab .user-chat-box  .img-box img {
  border-radius: 6px;
  width: 36px;
  height: 36px;
  max-width: 100%;
}
.selected-user-tab .user-chat-box img.close{
  width: 15px;
  height: 15px;
  margin-left: auto;
  cursor: pointer;
}
.selected-box {
  overflow-x: scroll;
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 10px;
  padding-bottom: 8px;
}
.user-item {
  min-width: 128px;
  margin: 0 10px;
}


#scroll::-webkit-scrollbar {
  height: 6px;
  background-color: white;
  border-radius: 50px;
  padding: 12px 0 0 0 ;
  display: none;
}

#scroll::-webkit-scrollbar-thumb {
  background-color: var(--active-color);
  border-radius: 50px;
}

#scroll::-webkit-scrollbar-button {
  display: none;
  
}

.next-btn-box {
  display: flex;
  justify-content: center;
}
.next-btn-box .img-box img{
  width: 64px;
  height: 60px;

}
.add-group-icon span {
  color: white;
  text-align: center;
}

.image.add-image{
  margin: 0 auto;
  width: 150px;
  height: 150px;
  border-radius: 50% !important;
  overflow: hidden;
}

.add-group-icon img {
  width: auto;
}

 .image img.user-profile {
  width: 100%;
  max-width: 100%;
  border-radius: 50%;
}

 .image img.profile-update{
  max-width: 100%;
  width: 100%;
  border-radius: 50%;
  background: var(--chat-35373D);
}
.image .change-img {
  position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50% , -50%);
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
}
.image .change-img img {
  width: 28px;
  height: 24px;
  display: block;
}
.selected-user-tab .input-group{
 position: relative;
}
.selected-user-tab .emoji-box {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  z-index: 111;
}
.selected-user-tab .emoji-box i{
  font-size: 30px;
  color: var(--chat-active);
}

/* Modal css start */
.payment-card {
  padding: 0;
  background: var(--dark-bg-color);
  background-position: center;
  background-size: cover;
  border-radius: 15px;
}
.modal button.btn-close {
  position: absolute;
  right: 14px;
  top: 14px;
  opacity: 1;
  background-image: url('../images/modal-close-btn.png');
  background-position: center;
  background-size: contain;
  z-index: 1056;
}
.modal  .payment-card .modal-header {
  padding: 20px 0;
  justify-content: center;
  border: 0;
  background: var(--light-black);
}
.modal  .payment-card .modal-header h5 {
  font-size: 20px;
  font-weight: 400;
}
.common-form  .input-group textarea:focus {
  outline: none;
}
.common-form .input-group, .modal-body form .input-group {
  padding-bottom: 15px;
}
.common-form .input-group textarea {
  background: var(--dark-bg-color);
  border: 1px solid var(--border-color);
  color: var(--white);
  border-radius: 8px !important;
  font-size: 16px;
  width: 100%;
  padding: 12px 6px;
}
/* Modal css end */


/* Chat inbox part css start here */
#chatSection .chat-box-right .msg_card_body {
  height: calc(100vh - 156px);
  overflow-y: auto;
  padding: 10px 25px;
  background: url('../images/chat-bg.png');
  background: linear-gradient(to top, #3b383865, #3b383865), url('../images/chat-bg-2.png')no-repeat top center;
  background-size: 100% 100%;
  background-position: center;
}

#chatSection .chat-box-right .msg_card_body> div{
  margin-bottom: 10px;
}

#chatSection .chat-box-right .message{
  max-width: 70%;
}
#chatSection .chat-box-right .message-recive {
  text-align: left;
 
}
#chatSection .chat-box-right .message-send {
  text-align: right;
}
#chatSection .chat-box-right .message .msg_cotainer{
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 15px 15px 15px 5px;
  background-color: var(--white);
  padding: 15px 30px 15px 10px;
  position: relative;
  color: var(--black);
  max-width: 100%;
}

#chatSection .chat-box-right .img_cont_msg {
max-width: 100%;
}
#chatSection .chat-box-right .message-recive .img_cont_msg {
  border-radius: 15px 15px 15px 5px;
  overflow: hidden;
}
#chatSection .chat-box-right .message-send .img_cont_msg {
  border-radius: 15px 15px 5px 15px;
  overflow: hidden;
}

#chatSection .chat-box-right .img_cont_msg > img {
  max-width: 100%;
}
#chatSection .message .msg_cotainer_send{
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 15px 15px 5px 15px;
  background-color: var(--orange-E67A30);
  padding: 15px 30px 15px 25px;
  color: var(--black);
  max-width: 100%;
  position: relative;
}
#chatSection  .msg_time{
  color: var(--white);
  font-size: 14px;
}
#chatSection  .msg_time_send{
  color: var(--white);
  font-size: 14px;
}
#chatSection  .msg_time i,
#chatSection  .msg_time_send i{
  font-size: 10px;
  margin-left: 5px;
}
.message-dropdown {
  position: absolute;
  right: 10px;
  top: 0;
  opacity: 0;
}
.message-dropdown .dropdown-menu {
  background: var(--dark-bg-color);
  border-radius: 0 10px 10px 10px;
}
.message-dropdown .dropdown-menu .dropdown-item{
  color: var(--white);
}
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--white) !important;
  background-color: var(--dark-bg-color);
}
#chatSection .message .msg_cotainer_send:hover .message-dropdown,
#chatSection .message .msg_cotainer:hover .message-dropdown{
  opacity: 1;
}
.message-dropdown .dropdown-toggle::after{
  display: none;
}

/* 
.sticker{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background-color: var(--white);
  padding: 7px 7px 7px 11px;
  width: 250px;
  max-width: 100%;
}
.sticker .img-box{
  width: 50%;
}
.sticker .img-box img{
  width: auto;
}
.sticker .user-data {
  text-align: left;
}
.sticker .user-data span.type {
  padding: 5px 12px;
  border-radius: 50px;
  background-color: var(--black);
  font-family: "Fredoka-Regular";
  font-size: 14px;
  color: var(--white);
  letter-spacing: 1.1px;
  line-height: 1;
  margin-bottom: 10px;
}
.sticker p:not(.amount) {
  margin-bottom: 0;
  color: var(--black);
  font-size: 16px;
  line-height: 1.4;
}
.sticker p.amount{
  font-family: "Fredoka-Medium";
  font-size: 21px;
  margin-bottom: 0;
} */


/* Chat inbox part css end here */

#chatSection  .chat-box-right .box-footer {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  color: white;
  column-gap: 20px;
  background: var(--footer-bg);
}

#chatSection  .chat-box-right .box-footer img.footer-img{
  width: auto;
  max-width: max-content;
}
#chatSection  .chat-box-right .box-footer form{
  flex-grow: 1;
}
#chatSection  .chat-box-right .box-footer .input-field {
  flex-grow: 2;
  position: relative;
}
#chatSection  .chat-box-right .box-footer .input-field .test {
  padding: 10px 20px;
  border: none;
  width: 100%;
  border-radius: 50px;
}
#chatSection  .chat-box-right .box-footer .input-field .chat-control {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
}


/* starred messages  */

#chatSection .chat-box-left .message {
  text-align: left;
}
#chatSection .chat-box-left .message .msg_cotainer{
  margin-top: auto;
  margin-bottom: auto;
  background-color: var(--white);
  padding: 8px 25px 8px 10px;
  position: relative;
  color: var(--black);
  max-width: 100%;
}
#chatSection .chat-box-left .star-message-box .msg_cotainer,
#chatSection .chat-box-left .star-message-box .msg_cotainer_send{
  padding:15px 30px 15px 10px ;
  border-radius: 15px 15px 15px 5px;
  width: max-content;
}

#chatSection .chat-box-left .star-message-box:not(:last-child) {
  padding-bottom:12px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--gray-text);
}
#chatSection .chat-box-left .contact-profile {
  display: flex;
  align-items: center;
  padding: 0;
  padding-bottom: 12px;
  background: transparent;
}
#chatSection .chat-box-left .star-message-box .contact-profile img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
#chatSection .chat-box-left .contact-profile .user-info {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
#chatSection .chat-box-left .contact-profile .user-info span {
  color: white;
}
#chatSection .chat-box-left .contact-profile .user-info .dot{
  height: 5px;
  width: 5px;
  background: var(--white);
  border-radius: 50%;
  display: inline-block;
}

#chatSection .chat-box-left .contact-profile .time {
  margin-left: auto;
  color: var(--white);
  display: flex;
  align-items: center;
  column-gap: 9px;
}

/* .left-user-box {
  height: 100vh;
} */
/* Setting page  */

.setting .left-user-box .chat-box-left .contact-profile {
  display: flex;
  align-items: center;
  justify-content: start !important;
  margin: 20px 0;
}
.setting .contact-profile h5{
  font-size: 22px;
  font-weight: normal;
}
.setting .contact-profile .img-box{
  margin-right: 12px;
}
/* .setting-box {
  margin-top: 30px;
} */
.setting-box a{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting-box a .img-box{
  margin-right: 20px;
}
.setting-box a .setting-tab{
  width: 100%;
  color: var(--white);
  font-size: 18px;
  border-bottom: 1px solid var(--chat-active);
  padding: 25px 0;
}
.setting-box a .setting-tab.red{
  color: var(--red);
}


.toggle-sidebar-section .chat-box-right {
  width: 100%;
}
.toggle-sidebar-section .profile-info-box {
  /* width: 50%; */
  /* display: none; */
  background-color: var(--dark-bg-color);
  overflow-y: auto;
  height: 100vh;
  
}
.toggle-sidebar-section .profile-info-box .profile-header{
  background: var(--profile-header-color);
  display: flex;
  padding: 30px 30px;
  column-gap: 25px;
}

.toggle-sidebar-section .profile-info-box .profile-header .close-btn img {
  width: 24px;
  height: 24px;
}
.toggle-sidebar-section .profile-info-box .profile-header .user-info h5{
  font-size: 20px;
  font-weight: 500;
}
.toggle-sidebar-section .profile-info-box .profile-detail-box {
  padding: 20px 20px;
}
.toggle-sidebar-section .profile-info-box .profile-img-box {
 border-bottom: 1px solid var(--gray-text);
 padding:0 0 20px 0;
 text-align: center;
}
.toggle-sidebar-section .profile-info-box .profile-img-box img {
  width: 170px;
  height: 170px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 100%
}
.toggle-sidebar-section .profile-info-box .profile-img-box .group-name {
  margin-top: 10px;
  position: relative;
}
.toggle-sidebar-section .profile-info-box .profile-img-box .group-name p {
  font-size: 22px;
  display: inline-block;
  margin-bottom: 0;
}
.toggle-sidebar-section .profile-info-box .profile-img-box .group-name i {
  color: var(--chat-active);
  margin-left: 12px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.toggle-sidebar-section .profile-info-box .profile-img-box .group-members span {
  color: var(--gray-text);
}
.toggle-sidebar-section .profile-info-box .group-member-box{
 border-bottom: 1px solid var(--gray-text);

}
.toggle-sidebar-section .profile-info-box .group-member-box p {
  margin: 8px 0;
}
.toggle-sidebar-section .profile-info-box .group-member-box nav {
  height: auto !important;
}
.toggle-sidebar-section .profile-info-box .group-member-box nav .nav-link {
  margin-bottom: 10px 
  !important;
}

.profile-info-box label.form-lable {
  color: var(--white);
  font-size: 20px;
}

.profile-info-box .emoji-box {
  position: absolute;
  right: 0;
  top: 40px;
  cursor: pointer;
  z-index: 111;
}
.profile-info-box .emoji-box i {
  font-size: 20px;
  color: var(--chat-active);
}

.chat-box.chat-box-left.profile-setting {
  height: calc(100vh - 121px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#chatSection .chat-box-right .voice-message {
  justify-content: end;
  display: none;
}

.change-img {
  opacity: 0;
}
.add-image:hover .profile-update{
  filter: opacity(0.5);
}
.add-image:hover .change-img{
  opacity: 1;
  z-index: 888;
}
.image input.form-control{
  z-index: 999;
}
.group-chat-messages {
  background: var(--white);
  border-radius: 15px 15px 15px 5px;
  overflow: hidden;
}
.group-chat-messages .sender-name {
  padding: 1px 0 0 10px;
  background: #D9D9D9;
  font-size: 11px;
}
#chatSection .chat-box-right .message .group-chat-messages .msg_cotainer {
  border-radius: 0;
}
.msg_cotainer.img_cont_msg.position-relative {
  padding: 2px 3px !important;
}


/* NEW  */
.infinite-scroll-component > div {
  margin-bottom: 12px;
}
.loader {
  text-align: center;
  width: 35px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid #4ADADA;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}
.infinite-scroll-component > div {
  margin-bottom: 12px;
}

/* Voice note message css  */

.voice-message-box{
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.voice-message-box .play-pause-btn {
  height: 40px;
  width: 40px;
  background: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 52%;
}

.play-pause-btn .button {
  box-sizing: border-box;
  width: 0;
  height: 12px;
  border-style: solid;
  border-width: 6px 0 6px 11px;
  border-color: transparent transparent transparent var(--white);
  cursor: pointer;
  will-change: border-width;
  transition: all .2s ease;
}

.play-pause-btn .button.paused {
  border-style: double;
  border-width: 0px 0 0px 11px;
}

.voice-message-box .progress {
  height: 8px;
  width: 200px;
  overflow: visible;
  background: var(--light-gray);
}

.voice-message-box .progress .progress-bar {
  background-color: var(--orange-E67A30);
  position: relative;
  overflow: visible;
  border-radius: 50px;
}
.voice-message-box .progress .progress-bar::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background: var(--orange-E67A30);
  border-radius: 50%;
}

/* Recive img css */

.img_cont_msg {
  max-width: 100% !important;
  width: 300px !important;
}
#chatSection .chat-box-right .img_cont_msg > img {
  max-width: 100%;
  border-radius: 15px 15px 15px 5px;
  width: 100%;
}

/* send img css */

.img_cont_msg_send {
  max-width: 100% !important;
  width: 300px !important;
  overflow: hidden;
}
#chatSection .chat-box-right .img_cont_msg_send {
  padding: 3px;
}
#chatSection .chat-box-right .img_cont_msg_send:hover .message-dropdown{
   opacity: 1;
}
#chatSection .chat-box-right .img_cont_msg_send > img{
  max-width: 100% !important;
  width: 300px !important;
  border-radius: 15px 15px 0 15px ;
}

/* send video css */
#chatSection .chat-box-right .video_cont_send {
  max-width: 100% !important;
  width: 300px !important;
  padding: 3px;
}
#chatSection .chat-box-right .video_cont_send video {
  max-width: 100% !important;
  width: 300px !important;
  border-radius: 15px 15px 0 15px ;
}

 
